import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import {
  BookmarkedLessonsResponse,
  EnrolledLessonsResponse,
  EnrolledUsersResponse,
  Status,
} from "../__generated__/graphql";
import {
  ADD_USER_ENROLLED_LESSON_MUTATION,
  CANCEL_SUBSCRIPTION_MUTATION,
  CREATE_CHECKOUT_SESSION_MUTATION,
  CREATE_CUSTOMER_PORTAL_SESSION_MUTATION,
  CREATE_USER_MUTATION,
  FETCH_ENROLLED_USERS_QUERY,
  FETCH_ORGANIZATION_BY_DOMAIN_QUERY,
  FETCH_SIGNED_IN_USER_QUERY,
  FETCH_USER_BOOKMARKED_LESSONS_QUERY,
  FETCH_USER_ENROLLED_LESSONS_QUERY,
  FETCH_USER_PAGES_COMPLETED_QUERY,
  FETCH_USER_QUERY,
  GET_CHECKOUT_SESSION,
  GET_USER_SUBSCRIPTION_STATUS,
  REMOVE_USER_BOOKMARKED_LESSON_MUTATION,
  REMOVE_USER_ENROLLED_LESSON_MUTATION,
  REMOVE_USER_PAGE_COMPLETED_MUTATION,
  SAVE_USER_BOOKMARKED_LESSON_MUTATION,
  SAVE_USER_PAGE_COMPLETED_MUTATION,
  UPDATE_USER_PROFILE_MUTATION,
} from "../graphql";
import { Clients } from "../index";
import {
  BooleanResponse,
  CheckoutSession,
  CheckoutSessionStatus,
  OnboardingDetailsWithProfilePictureUrl,
  SignupDetails,
  SubscriptionStatus,
  User,
  UserPagesCompleted,
} from "../types/auth";
import { Organization } from "../types/organization";

interface UseUserApiClient {
  loading: boolean;
  signedInUser: User | null;
  organization: Organization;
  organizationRef: React.MutableRefObject<Organization>;
  fetchUserById: (userId: string) => Promise<User>;
  fetchUsersLessonPagesCompleted: (
    lessonId: string
  ) => Promise<UserPagesCompleted>;
  saveUserPageCompleted: (
    lessonId: string,
    pageId: string
  ) => Promise<BooleanResponse>;
  removeUserPageCompleted: (
    lessonId: string,
    pageId: string
  ) => Promise<BooleanResponse>;
  fetchUserBookmarkedLessons: () => Promise<BookmarkedLessonsResponse>;
  bookmarkLesson: (lessonId: string) => Promise<BooleanResponse>;
  removeBookmarkedLesson: (lessonId: string) => Promise<BooleanResponse>;
  fetchEnrolledUsersForLesson: (
    lessonId: string
  ) => Promise<EnrolledUsersResponse>;
  fetchUserEnrolledLessons: () => Promise<EnrolledLessonsResponse>;
  getCheckoutSessionStatus: (
    sessionId: string
  ) => Promise<CheckoutSessionStatus>;
  createCheckoutSession: (organizationId: string) => Promise<CheckoutSession>;
  enrollLesson: (lessonId: string) => Promise<BooleanResponse>;
  createCustomerPortalSession: (organizationId: string) => Promise<string>;
  getUserSubscriptionStatus: (
    organizationId: string
  ) => Promise<SubscriptionStatus>;
  removeEnrolledLesson: (lessonId: string) => Promise<BooleanResponse>;
  cancelSubscription: (organizationId: string) => Promise<boolean>;
  createUser: (signupDetails: SignupDetails) => Promise<User>;
  updateUserProfile: (
    onboardingDetails: OnboardingDetailsWithProfilePictureUrl
  ) => Promise<User>;
}

export function useUserApiClient(): UseUserApiClient {
  const [fetchUserQuery] = useLazyQuery(FETCH_USER_QUERY, {
    client: Clients.USER,
  });
  const [fetchOrganizationByDomainQuery] = useLazyQuery(
    FETCH_ORGANIZATION_BY_DOMAIN_QUERY,
    {
      client: Clients.USER,
    }
  );
  const [fetchSignedInUserQuery] = useLazyQuery(FETCH_SIGNED_IN_USER_QUERY, {
    client: Clients.USER,
  });
  const [fetchUsersPagesCompletedQuery] = useLazyQuery(
    FETCH_USER_PAGES_COMPLETED_QUERY,
    {
      client: Clients.USER,
    }
  );
  const [fetchCheckoutSession] = useLazyQuery(GET_CHECKOUT_SESSION, {
    client: Clients.USER,
    fetchPolicy: "network-only",
  });

  const [getUserSubscriptionStatusQuery] = useLazyQuery(
    GET_USER_SUBSCRIPTION_STATUS,
    {
      client: Clients.USER,
      fetchPolicy: "network-only",
    }
  );

  const [cancelSubscriptionMutation] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
    {
      client: Clients.USER,
    }
  );

  const [createCustomerPortalSessionMutation] = useMutation(
    CREATE_CUSTOMER_PORTAL_SESSION_MUTATION,
    {
      client: Clients.USER,
    }
  );

  const [createCheckoutSessionMutation] = useMutation(
    CREATE_CHECKOUT_SESSION_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [saveUserPageCompletedMutation] = useMutation(
    SAVE_USER_PAGE_COMPLETED_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [removeUserPagesCompletedMutation] = useMutation(
    REMOVE_USER_PAGE_COMPLETED_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [fetchUserBookmarkedLessonsQuery] = useLazyQuery(
    FETCH_USER_BOOKMARKED_LESSONS_QUERY,
    {
      client: Clients.USER,
    }
  );
  const [bookmarkLessonMutation] = useMutation(
    SAVE_USER_BOOKMARKED_LESSON_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [removeBookmarkedLessonMutation] = useMutation(
    REMOVE_USER_BOOKMARKED_LESSON_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [fetchUserEnrolledLessonsQuery] = useLazyQuery(
    FETCH_USER_ENROLLED_LESSONS_QUERY,
    {
      client: Clients.USER,
    }
  );
  const [fetchEnrolledUsersForLessonQuery] = useLazyQuery(
    FETCH_ENROLLED_USERS_QUERY,
    {
      client: Clients.USER,
    }
  );
  const [enrollLessonMutation] = useMutation(
    ADD_USER_ENROLLED_LESSON_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [removeEnrolledLessonMutation] = useMutation(
    REMOVE_USER_ENROLLED_LESSON_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION, {
    client: Clients.AUTH,
  });
  const [updateUserProfileMutation] = useMutation(
    UPDATE_USER_PROFILE_MUTATION,
    {
      client: Clients.USER,
    }
  );
  const [loading, setLoading] = useState(true);
  const [signedInUser, setSignedInUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<Organization>({
    id: "1",
    name: "",
    domain: window.location.hostname,
    stripeProductId: "1",
    stripePublishableKey:
      "pk_live_51OTTBZCvw19Je3sXDsOVao3La8BV6yCjg7DiL7ZEAVAxfdcq1QzAbu0gbTkstzqDUSJ3PZIQEhVdqCrV5Mm84tVe00Ufya9SR3",
    defaultPrompts: [
      {
        title: "Clarify",
        description: "Ask a question to clarify your understanding",
        icon: { type: "material", value: "help_outline" },
      },
    ],
  });

  const organizationRef = useRef<Organization>({
    id: "1",
    name: "",
    domain: window.location.hostname,
    stripeProductId: "1",
    stripePublishableKey:
      "pk_live_51OTTBZCvw19Je3sXDsOVao3La8BV6yCjg7DiL7ZEAVAxfdcq1QzAbu0gbTkstzqDUSJ3PZIQEhVdqCrV5Mm84tVe00Ufya9SR3",
    defaultPrompts: [
      {
        title: "Clarify",
        description: "Ask a question to clarify your understanding",
        icon: { type: "material", value: "help_outline" },
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchOrganizationByDomain(window.location.hostname)
        .then((organization) => {
          setOrganization(organization);
          organizationRef.current = organization;
        })
        .catch((e) => {
          console.error(e);
        }),
      fetchSignedInUser()
        .then((user) => setSignedInUser(user))
        .catch(() => setSignedInUser(null)),
    ]).finally(() => setLoading(false));
  }, []);

  const fetchUserById = React.useCallback(
    async (userId: string): Promise<User> => {
      setLoading(true);
      return await fetchUserQuery({
        variables: {
          getUserByIdId: userId,
        },
      })
        .then((result) => {
          if (!result.data?.userByID?.user) {
            throw new Error(
              result.data?.userByID?.error?.message ??
                "Error occurred while fetching user."
            );
          }
          const user = result.data.userByID?.user ?? undefined;
          return {
            ...user,
            id: user?.id,
            userName: user?.userName ?? undefined,
            firstName: user?.firstName ?? undefined,
            lastName: user?.lastName ?? undefined,
            profilePictureUrl: user?.profilePictureUrl ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchOrganizationByDomain = React.useCallback(
    async (domain: string): Promise<Organization> => {
      return await fetchOrganizationByDomainQuery({
        variables: {
          domain,
        },
        fetchPolicy: "cache-first",
      }).then((result) => {
        if (!result.data?.organizationByDomain?.data) {
          throw new Error(
            result.data?.organizationByDomain?.error?.message ??
              "Error occurred while fetching organization."
          );
        }
        const data = result.data.organizationByDomain.data;
        return {
          ...data,
          logoUrl: data.logoUrl ?? undefined,
          primaryColor: data.primaryColor ?? undefined,
          secondaryColor: data.secondaryColor ?? undefined,
          defaultPrompts:
            data.defaultPrompts?.map((prompt) => {
              return {
                title: prompt.title,
                description: prompt.description,
                icon: prompt.icon ?? undefined,
              };
            }) ?? [],
        };
      });
    },
    []
  );

  const fetchUsersLessonPagesCompleted = React.useCallback(
    async (lessonId: string): Promise<UserPagesCompleted> => {
      setLoading(true);
      return await fetchUsersPagesCompletedQuery({
        variables: {
          lessonId,
        },
      })
        .then((res) => ({
          pagesCompleted:
            res.data?.getLessonPagesCompletedForUser?.pagesCompleted,
        }))
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchSignedInUser = React.useCallback(async (): Promise<User> => {
    return await fetchSignedInUserQuery({ fetchPolicy: "network-only" }).then(
      (result) => {
        const user = result.data?.signedInUser?.user;

        if (!user) {
          throw new Error(
            result.data?.signedInUser?.error?.message ??
              "Unable to fetch signed in user."
          );
        }
        return {
          ...user,
          id: user.id,
          firstName: user.firstName ?? undefined,
          lastName: user.lastName ?? undefined,
          userName: user.userName ?? undefined,
          profilePictureUrl: user.profilePictureUrl ?? undefined,
        };
      }
    );
  }, []);

  const createCheckoutSession = React.useCallback(
    async (organizationId: string): Promise<CheckoutSession> => {
      setLoading(true);
      return await createCheckoutSessionMutation({
        variables: {
          organizationId,
        },
      })
        .then((result) => {
          const checkoutSessionId =
            result.data?.createCheckoutSession?.data?.id;
          const clientSecret =
            result.data?.createCheckoutSession?.data?.clientSecret;

          if (!checkoutSessionId || !clientSecret) {
            throw new Error(
              result.data?.createCheckoutSession?.error?.message ??
                "Unable to create subscription checkout session."
            );
          }
          return {
            checkoutSessionId,
            clientSecret,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const cancelSubscription = React.useCallback(
    async (organizationId: string): Promise<boolean> => {
      setLoading(true);
      return await cancelSubscriptionMutation({
        variables: {
          organizationId,
        },
      })
        .then((result) => {
          const subscriptionCanceled = result.data?.cancelSubscription?.success;

          if (!subscriptionCanceled) {
            throw new Error(
              result.data?.cancelSubscription?.error?.message ??
                "Unable to cancel subscription"
            );
          }
          return subscriptionCanceled;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const getUserSubscriptionStatus = React.useCallback(
    async (organizationId: string): Promise<SubscriptionStatus> => {
      return await getUserSubscriptionStatusQuery({
        variables: {
          organizationId,
        },
      }).then((result) => {
        console.log("organizationId", organizationId);
        console.log("result", result.data?.userSubscription?.data);
        const data = result.data?.userSubscription?.data;
        return {
          status: data?.status ?? Status.Inactive,
          startDate: data?.startDate ?? undefined,
          endDate: data?.endDate ?? undefined,
          cancellationDate: data?.cancellationDate ?? undefined,
        };
      });
    },
    []
  );

  const createCustomerPortalSession = React.useCallback(
    async (organizationId: string): Promise<string> => {
      setLoading(true);
      return await createCustomerPortalSessionMutation({
        variables: { organizationId },
      })
        .then((result) => {
          const url = result.data?.createCustomerPortalSession;
          if (!url) {
            throw new Error("Unable to create customer portal session.");
          }
          return url;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const getCheckoutSessionStatus = React.useCallback(
    async (sessionId: string): Promise<CheckoutSessionStatus> => {
      setLoading(true);
      return await fetchCheckoutSession({
        variables: {
          sessionId,
        },
      })
        .then((result) => {
          const checkoutSessionId =
            result.data?.checkoutSession?.data?.id ?? undefined;
          const checkoutSessionStatus =
            result.data?.checkoutSession?.data?.status ?? undefined;

          if (!checkoutSessionId || !checkoutSessionStatus) {
            throw new Error(
              result.data?.checkoutSession?.error?.message ??
                "Unable to get subscription checkout session status."
            );
          }
          return {
            checkoutSessionId,
            checkoutSessionStatus,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const saveUserPageCompleted = React.useCallback(
    async (lessonId: string, pageId: string): Promise<BooleanResponse> => {
      return await saveUserPageCompletedMutation({
        variables: { lessonId, pageId },
      }).then((result) => {
        return {
          success: result.data?.addLessonsPageCompleted?.success,
          error: result.data?.addLessonsPageCompleted?.error?.message,
        };
      });
    },
    []
  );

  const removeUserPageCompleted = React.useCallback(
    async (lessonId: string, pageId: string): Promise<BooleanResponse> => {
      return await removeUserPagesCompletedMutation({
        variables: { lessonId, pageId },
      })
        .then((result) => {
          return {
            success: result.data?.removeLessonsPageCompleted?.success,
            error: result.data?.removeLessonsPageCompleted?.error?.message,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchUserBookmarkedLessons =
    React.useCallback(async (): Promise<BookmarkedLessonsResponse> => {
      setLoading(true);
      return await fetchUserBookmarkedLessonsQuery({
        fetchPolicy: "network-only",
      })
        .then((res) => ({
          bookmarkedLessons: res.data?.getBookmarkedLessons?.bookmarkedLessons,
        }))
        .finally(() => setLoading(false));
    }, []);

  const bookmarkLesson = React.useCallback(
    async (lessonId: string): Promise<BooleanResponse> => {
      return await bookmarkLessonMutation({
        variables: { lessonId },
      }).then((result) => {
        return {
          success: result.data?.bookmarkLesson?.success,
          error: result.data?.bookmarkLesson?.error?.message,
        };
      });
    },
    []
  );

  const removeBookmarkedLesson = React.useCallback(
    async (lessonId: string): Promise<BooleanResponse> => {
      return await removeBookmarkedLessonMutation({
        variables: { lessonId },
      }).then((result) => {
        return {
          success: result.data?.removeBookmarkedLesson?.success,
          error: result.data?.removeBookmarkedLesson?.error?.message,
        };
      });
    },
    []
  );

  const fetchEnrolledUsersForLesson = React.useCallback(
    async (lessonId: string): Promise<EnrolledUsersResponse> => {
      setLoading(true);
      return await fetchEnrolledUsersForLessonQuery({
        variables: { lessonId },
        fetchPolicy: "network-only",
      })
        .then((res) => ({
          enrolledUserIds: res.data?.getEnrolledUsers?.enrolledUserIds,
        }))
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchUserEnrolledLessons =
    React.useCallback(async (): Promise<EnrolledLessonsResponse> => {
      setLoading(true);
      return await fetchUserEnrolledLessonsQuery({
        fetchPolicy: "network-only",
      })
        .then((res) => ({
          enrolledLessons: res.data?.getEnrolledLessons?.enrolledLessons,
        }))
        .finally(() => setLoading(false));
    }, []);

  const enrollLesson = React.useCallback(
    async (lessonId: string): Promise<BooleanResponse> => {
      return await enrollLessonMutation({
        variables: { lessonId },
      })
        .then((result) => {
          return {
            success: result.data?.enrollLesson?.success,
            error: result.data?.enrollLesson?.error?.message,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const removeEnrolledLesson = React.useCallback(
    async (lessonId: string): Promise<BooleanResponse> => {
      return await removeEnrolledLessonMutation({
        variables: { lessonId },
      })
        .then((result) => {
          return {
            success: result.data?.removeEnrolledLesson?.success,
            error: result.data?.removeEnrolledLesson?.error?.message,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const createUser = React.useCallback(
    async ({ email, password }: SignupDetails): Promise<User> => {
      setLoading(true);
      return await createUserMutation({
        variables: {
          email,
          password,
        },
      })
        .then((result) => {
          if (!result.data?.signUp?.user) {
            throw new Error(
              result.data?.signUp?.error?.message ?? "Unable to create user."
            );
          }
          return {
            ...result.data.signUp.user,
            id: result.data.signUp.user.id,
            userName: result.data.signUp.user.userName ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const updateUserProfile = React.useCallback(
    async ({
      firstName,
      userName,
      lastName,
      profilePictureUrl,
    }: OnboardingDetailsWithProfilePictureUrl): Promise<User> => {
      setLoading(true);
      return await updateUserProfileMutation({
        variables: {
          firstName,
          userName,
          lastName,
          profilePictureUrl,
        },
      })
        .then((result) => {
          if (!result.data?.updateUserProfile?.user) {
            throw new Error(
              result.data?.updateUserProfile?.error?.message ??
                "Unable to update user."
            );
          }
          return {
            ...result.data.updateUserProfile.user,
            firstName:
              result.data.updateUserProfile.user.firstName ?? undefined,
            lastName: result.data.updateUserProfile.user.lastName ?? undefined,
            userName: result.data.updateUserProfile.user.userName ?? undefined,
            profilePictureUrl:
              result.data.updateUserProfile.user.profilePictureUrl ?? undefined,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  return {
    loading,
    signedInUser,
    organization,
    organizationRef,
    fetchUserById,
    createCheckoutSession,
    getCheckoutSessionStatus,
    fetchUsersLessonPagesCompleted,
    saveUserPageCompleted,
    removeUserPageCompleted,
    fetchUserBookmarkedLessons,
    bookmarkLesson,
    removeBookmarkedLesson,
    fetchEnrolledUsersForLesson,
    fetchUserEnrolledLessons,
    cancelSubscription,
    enrollLesson,
    removeEnrolledLesson,
    getUserSubscriptionStatus,
    createUser,
    updateUserProfile,
    createCustomerPortalSession,
  };
}
