import React from "react";
import styles from "./CheckOrganizationComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { LoadingComponentFullScreen } from "../LoadingComponent";

interface CheckOrganizationComponentProps {
  children: React.ReactNode;
}

export default function CheckOrganizationComponent({
  children,
}: CheckOrganizationComponentProps): React.JSX.Element {
  const { loading } = useUserApiClient();

  return (
    <div className={styles.container}>
      {loading ? <LoadingComponentFullScreen /> : children}
    </div>
  );
}
