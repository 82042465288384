import React from "react";
import styles from "./LoadingComponent.module.css";
import IconLogo from "../Logo/icon-logo";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

export const LoadingComponentContained = (): JSX.Element => {
  const { organization } = useUserApiClient();
  return (
    <div className={styles.containerContained}>
      {organization.logoUrl ? (
        <img src={organization.logoUrl} style={{ width: "150px" }} />
      ) : (
        <IconLogo height="150px" />
      )}
      <span className={styles.title}>{organization.name}</span>
    </div>
  );
};

export const LoadingComponentFullScreen = (): JSX.Element => {
  const { organization } = useUserApiClient();

  return (
    <div className={styles.container}>
      <img src={organization.logoUrl} style={{ width: "150px" }} />
      <span
        className={styles.title}
        style={{ color: organization.primaryColor }}
      >
        {organization.name}
      </span>
    </div>
  );
};
