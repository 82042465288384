import React from "react";
import styles from "./MainHeaderComponent.module.css";
import { Link } from "react-router-dom";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
interface MainHeaderComponentProps {
  headerElements: React.ReactNode;
}

export default function MainHeaderComponent({
  headerElements,
}: MainHeaderComponentProps): JSX.Element {
  const { organization } = useUserApiClient();
  return (
    <div className={styles.container}>
      <Link to="/">
        <img src={organization.logoUrl} style={{ width: "10rem" }} />
      </Link>
      <div className={styles.signupContainer}>{headerElements}</div>
    </div>
  );
}
