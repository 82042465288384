import React, { ChangeEvent } from "react";
import styles from "./user-details.module.css";
import { ProfilePictureUploadButton } from "./profile-picture-upload";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";
import { uploadPublicAsset } from "../../../../rest-clients/FileService";
import { SubscriptionStatus, User } from "../../../../types/auth";
import { Tooltip } from "@mui/material";
import { Status } from "../../../../__generated__/graphql";
import SubscriptionManagementModal from "../../../Subscription/SubscriptionManagementModal";

interface UserDetailsProps {
  user: User;
  signedInUser?: User;
  lessonCount: number;
  setError: (error: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const UserDetails = ({
  user,
  lessonCount,
  setError,
  setIsLoading,
  signedInUser,
}: UserDetailsProps): JSX.Element => {
  const {
    organization,
    organizationRef,
    updateUserProfile,
    getUserSubscriptionStatus,
    createCustomerPortalSession,
  } = useUserApiClient();
  const [subscription, setSubscription] = React.useState<SubscriptionStatus>();
  const [showManageSubscriptionModal, setShowManageSubscriptionModal] =
    React.useState<boolean>();
  const [profilePictureUrl, setProfilePictureUrl] = React.useState(
    user.profilePictureUrl
  );

  React.useEffect(() => {
    console.log("first", organizationRef.current.id);
    getUserSubscriptionStatus(organizationRef.current.id)
      .then((newSubscription) => {
        // console.log("second", organizationRef.current.id);
        // console.log("subscription", newSubscrip  tion);
        setSubscription(newSubscription);
      })
      .catch((error) => {
        console.error("Error getting subscription status:", error);
      });
  }, [organizationRef.current.id]);

  const handleOpenCustomerPortal =
    React.useCallback(async (): Promise<void> => {
      try {
        const url = await createCustomerPortalSession(organization.id);
        window.location.href = url;
      } catch (error) {
        console.error("Error creating customer portal session:", error);
      }
    }, [organization.id]);

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    event.preventDefault();
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      console.log("Selected file:", file.name);
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await uploadPublicAsset(formData);
        if (response.success) {
          console.log("Uploaded profile picture URL:", response.assetUrl);
          setProfilePictureUrl(profilePictureUrl);
          updateUserProfile({
            firstName: signedInUser?.firstName,
            userName: signedInUser?.userName ?? "",
            lastName: signedInUser?.lastName,
            profilePictureUrl: response.assetUrl,
          })
            .then(() => {
              setIsLoading(false);
              window.location.reload();
            })
            .catch((error) => {
              if (error instanceof Error) {
                console.error(error.message);
              }
              setError(error.message);
              console.error("Error occurred while updating profile picture");
            });
        } else {
          setError(response.error);
          console.error("Error uploading profile picture:", response.error);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.profileImage}>
        {profilePictureUrl ? (
          <img src={profilePictureUrl} alt="" style={{ background: "white" }} />
        ) : (
          <div
            className={styles.genericImage}
            style={{ backgroundColor: organization.primaryColor }}
          >
            {user.firstName ? user.firstName[0] : "A"}.
            {user.lastName ? user.lastName[0] : "U"}
          </div>
        )}
        {signedInUser?.id === user.id && (
          <ProfilePictureUploadButton handleFileChange={handleFileChange} />
        )}
      </div>
      <div className={styles.userDetails}>
        <div className={styles.userName}>
          {user?.firstName && user.lastName
            ? `${user?.firstName} ${user?.lastName}`
            : "Anonymous User"}
        </div>
        <div className={styles.lessonCount}>
          {lessonCount >= 1 ? `🔥 ${lessonCount} lessons` : "No lessons"}
        </div>
        {subscription && (
          <>
            <Tooltip title="Manage Subscription">
              <div
                className={styles.userSubscription}
                onClick={() => setShowManageSubscriptionModal(true)}
                style={{ borderColor: organization.primaryColor }}
              >
                {subscription?.status === Status.Active ? "Premium" : "Basic"}
              </div>
            </Tooltip>
            {subscription?.status === Status.Active && (
              <div
                className={styles.userSubscription}
                onClick={() => {
                  void handleOpenCustomerPortal();
                }}
                style={{ borderColor: organization.primaryColor }}
              >
                Manage Subscription
              </div>
            )}

            {showManageSubscriptionModal && (
              <SubscriptionManagementModal
                subscription={subscription}
                closeModal={() => setShowManageSubscriptionModal(false)}
              />
            )}
          </>
        )}

        {/* {isEditing ? (
          <div className={styles.editProfileButton}>Save Changes</div>
        ) : (
          <div
            className={styles.editProfileButton}
            onClick={() => setIsEditing(true)}
          >
            Edit Profile
          </div>
        )} */}
      </div>
    </div>
  );
};
