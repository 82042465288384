import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./CheckSubscriptionComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { Status } from "../../../__generated__/graphql";
import { LoadingComponentFullScreen } from "../LoadingComponent";

interface CheckSubscriptionComponentProps {
  children: React.ReactNode;
}

export default function CheckSubscriptionComponent({
  children,
}: CheckSubscriptionComponentProps): React.JSX.Element {
  const { getUserSubscriptionStatus, organization } = useUserApiClient();

  const [loading, setLoading] = React.useState(true);
  const [isUserSubscribed, setIsUserSubscribed] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    getUserSubscriptionStatus(organization.id)
      .then((data) => {
        if (data.status === Status.Active) {
          setIsUserSubscribed(true);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organization.id]);

  return (
    <div className={styles.container}>
      {loading ? (
        <LoadingComponentFullScreen />
      ) : !isUserSubscribed ? (
        <Redirect to="/subscriptions" />
      ) : (
        children
      )}
    </div>
  );
}
