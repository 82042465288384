import React from "react";
import { useResizable } from "react-resizable-layout";
import { ChamIDE } from "../ChamIDE";
import HeaderComponent from "../DashboardHeaderComponent";
import styles from "./LessonLayout.module.css";
import { TerminalHighlightProvider } from "../ChamIDE/contexts/TerminalHighlightContext";
import { SideBar } from "./Sidebar/Sidebar";
import ErrorComponent from "../ErrorComponent";
import { DirectoryProvider } from "../ChamIDE/contexts/DirectoryContext/DirectoryContext";
import { TerminalSessionProvider } from "../ChamIDE/contexts/TerminalSessionContext/TerminalSessionContext";
import { useViewContext } from "../ChamIDE/contexts/ViewContext/ViewContext";
import { MonacoProvider } from "../ChamIDE/contexts/MonacoContext/MonacoContext";
import { TerminalContainer } from "../ChamIDE/components/ChamIDEContainer/TerminalContainer";
import { ViewState } from "./types";
import { ChamBrowserContainer } from "../ChamIDE/components/ChamBrowserContainer/ChamBrowserContainer";
import { ExecutionServiceProvider } from "../ChamIDE/contexts/ExecutionServiceContext/ExecutionServiceContext";
import {
  DeviceType,
  useResponsiveness,
} from "../../../hooks/useResponsiveness";

interface ILayoutProps {
  children: JSX.Element;
}

function Layout(props: ILayoutProps): React.JSX.Element {
  const [allowTextSelection, setAllowTextSelection] = React.useState(true);
  const [showChamBrowser, setShowChamBrowser] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const { currentView } = useViewContext();
  const [actualSidebarWidth, setActualSidebarWidth] = React.useState(250);
  const toggleShowChamBrowser = React.useCallback(() => {
    setShowChamBrowser(!showChamBrowser);
  }, [showChamBrowser]);
  const { deviceType } = useResponsiveness();

  const disableHighlight = React.useCallback(() => {
    setAllowTextSelection(false);
  }, []);

  const enableHighlight = React.useCallback(() => {
    setAllowTextSelection(true);
  }, []);

  const { position: workspaceW, separatorProps: workspaceSeparatorProps } =
    useResizable({
      axis: "x",
      initial: 250,
      min: 250,
    });
  const { position: contentWidth, separatorProps: contentSeparatorProps } =
    useResizable({
      axis: "x",
      initial: window.innerWidth / 2,
      min: 50,
    });

  return (
    <>
      <TerminalHighlightProvider>
        <DirectoryProvider>
          <TerminalSessionProvider>
            <MonacoProvider>
              <ExecutionServiceProvider>
                {deviceType === DeviceType.Desktop ? (
                  <div
                    className={`${styles.container} ${allowTextSelection ? "" : styles.noSelect}`}
                  >
                    <HeaderComponent />
                    <div className={styles.mainWindow}>
                      {error && (
                        <div className={styles.errorComponentContainer}>
                          <ErrorComponent
                            title="Something went wrong"
                            close={() => setError("")}
                          >
                            {error}
                          </ErrorComponent>
                        </div>
                      )}
                      <SideBar
                        setError={setError}
                        width={workspaceW}
                        setActualWidth={setActualSidebarWidth}
                        actualWidth={actualSidebarWidth}
                      />
                      <div
                        className={
                          styles.divider + " " + styles.workspaceDivider
                        }
                        {...workspaceSeparatorProps}
                        onMouseDown={disableHighlight}
                        onMouseUp={enableHighlight}
                      ></div>

                      <div
                        className={styles.contentEditor}
                        style={{
                          width: contentWidth - actualSidebarWidth,
                        }}
                      >
                        {props.children}
                      </div>
                      <div
                        className={styles.divider}
                        {...contentSeparatorProps}
                        onMouseDown={disableHighlight}
                        onMouseUp={enableHighlight}
                      ></div>
                      <div className={styles.codeEditor}>
                        <ChamIDE
                          showChamBrowser={showChamBrowser}
                          toggleShowChamBrowser={toggleShowChamBrowser}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.containerMobile}>
                    <HeaderComponent />
                    <div className={styles.mainWindow}>
                      <SideBar
                        setError={setError}
                        width={workspaceW}
                        setActualWidth={setActualSidebarWidth}
                        actualWidth={actualSidebarWidth}
                      />

                      <div
                        className={`${styles.contentEditor} ${currentView === ViewState.Lesson || currentView === ViewState.Github || currentView === ViewState.ClarificationChat ? "" : styles.hidden}`}
                      >
                        {props.children}
                      </div>
                      <div
                        className={`${styles.codeEditor} ${currentView === ViewState.IDE ? "" : styles.hidden}`}
                      >
                        <ChamIDE
                          showChamBrowser={showChamBrowser}
                          toggleShowChamBrowser={toggleShowChamBrowser}
                        />
                      </div>

                      <div
                        className={`${styles.codeEditor} ${currentView === ViewState.Terminal ? "" : styles.hidden}`}
                      >
                        <TerminalContainer setError={setError} />
                      </div>
                      <div
                        className={`${styles.codeEditor} ${currentView === ViewState.Browser ? "" : styles.hidden}`}
                      >
                        <ChamBrowserContainer
                          toggleShowChamBrowser={toggleShowChamBrowser}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ExecutionServiceProvider>
            </MonacoProvider>
          </TerminalSessionProvider>
        </DirectoryProvider>
      </TerminalHighlightProvider>
    </>
  );
}

export default Layout;
