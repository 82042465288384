import { faBookmark as faBookmarkTransparent } from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark,
  faCircle,
  faPen,
  faShareNodes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appDomain } from "../../../config";
import { useUserApiClient } from "../../../hooks/useUserApiClient";
import { User } from "../../../types/auth";
import { ILesson } from "../../../types/lesson";
import { CircularProgressBarComponent } from "../ProgressBarComponent";
import { useSavedLessonIds } from "../SavedLessonContext/SavedLessonContext";
import styles from "./LessonCardVertical.module.css";
import {
  calculateTimeAgo,
  hashString,
  selectRandomLessonImageDesktop,
} from "./utils";
import useLessonApiClient from "../../../hooks/useLessonApiClient";

interface LessonCardProps {
  lesson: ILesson;
  clickUrl: string;
  showLessonEditModal?: (lessonId: string) => void;
  canBeDeleted?: boolean;
}

export const LessonCardVertical: React.FC<LessonCardProps> = ({
  lesson,
  clickUrl,
  showLessonEditModal,
  canBeDeleted = false,
}: LessonCardProps) => {
  const [author, setAuthor] = useState<User | undefined>();
  const [pagesCompleted, setPagesCompleted] = useState<string[]>([]);
  const [enrolledUsers, setEnrolledUsers] = useState<User[]>([]);
  const { savedLessons, setSavedLessons } = useSavedLessonIds();

  const {
    fetchUserById,
    fetchUsersLessonPagesCompleted,
    bookmarkLesson,
    removeBookmarkedLesson,
    removeEnrolledLesson,
    fetchEnrolledUsersForLesson,
  } = useUserApiClient();
  const { deleteLesson } = useLessonApiClient();

  const lessonProgress = React.useMemo(() => {
    const progress = Math.floor(
      ((pagesCompleted?.length ?? 0) / Math.max(lesson.pages.length, 1)) * 100
    );
    // Hacky hack if lesson 100% complete unenrol
    if (progress === 100) {
      void removeEnrolledLesson(lesson.id);
    }
    return progress;
  }, [pagesCompleted?.length, lesson.pages]);
  const { organization } = useUserApiClient();

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();
      e.stopPropagation();

      deleteLesson(lesson.id)
        .then(() => {
          console.log("Lesson successfully deleted");
        })
        .catch((error) => {
          console.error("Error deleting lesson:", error);
        });
    },
    [deleteLesson, lesson.id]
  );

  const formatDuration = useCallback((duration: number): string => {
    if (duration < 60) {
      return `${duration} second${duration === 1 ? "" : "s"}`;
    }
    if (duration < 3600) {
      return `${Math.round(duration / 60)} minute${
        Math.round(duration / 60) === 1 ? "" : "s"
      }`;
    }
    const hours = Math.round(duration / 3600);
    return `${hours} hour${hours === 1 ? "" : "s"}`;
  }, []);

  useEffect(() => {
    fetchUserById(lesson.authorId)
      .then((author) => setAuthor(author))
      .catch(() => setAuthor(undefined));
  }, [lesson.authorId]);

  useEffect(() => {
    const pageIds = new Set(lesson.pages.map((page) => page.id));
    fetchUsersLessonPagesCompleted(lesson.id)
      .then(({ pagesCompleted }) => {
        setPagesCompleted(
          pagesCompleted?.filter((pageId) => pageIds.has(pageId)) ?? []
        );
      })
      .catch(() => {
        setPagesCompleted([]);
      });
  }, [fetchUsersLessonPagesCompleted, lesson.id, lesson.pages]);

  useEffect(() => {
    fetchEnrolledUsersForLesson(lesson.id)
      .then(async ({ enrolledUserIds }) => {
        if (enrolledUserIds) {
          const usersPromises = enrolledUserIds.map(fetchUserById);
          await Promise.all(usersPromises).then((users) =>
            setEnrolledUsers(users)
          );
        }
      })
      .catch(() => setEnrolledUsers([]));
  }, [fetchEnrolledUsersForLesson, fetchUserById, lesson.id]);

  const defaultImage = React.useMemo(() => {
    const titleHash = hashString(lesson.title);
    return selectRandomLessonImageDesktop(titleHash);
  }, [lesson.title]);

  const handleToggleBookmark = React.useCallback(async () => {
    if (savedLessons.has(lesson.id)) {
      const removedLesson = await removeBookmarkedLesson(lesson.id);
      if (removedLesson.success) {
        savedLessons.delete(lesson.id);
        setSavedLessons(savedLessons);
      } else {
        console.error("Failed to remove bookmark");
      }
    } else {
      const addedLesson = await bookmarkLesson(lesson.id);
      if (addedLesson.success) {
        savedLessons.add(lesson.id);
        setSavedLessons(savedLessons);
      } else {
        console.error("Failed to bookmark lesson");
      }
    }
  }, [savedLessons]);

  const bookmarkIcon = savedLessons?.has(lesson.id)
    ? faBookmark
    : faBookmarkTransparent;

  const handleShareLesson = React.useCallback(async () => {
    const lessonUrl = `${appDomain}/lesson/${lesson.id}`;
    try {
      await navigator.clipboard.writeText(lessonUrl);
      alert(
        "Lesson URL successfully copied to clipboard!\n📋 Share it with anyone! 😊"
      );
    } catch (error) {
      console.error("Failed to copy lesson URL:", error);
      alert("Oops! Something went wrong. Please try again.");
    }
  }, [lesson.id, lesson.title]);

  return (
    <Link to={clickUrl} className={styles.lessonCard}>
      {/* TODO enable icons and images with backend */}
      <section className={styles.imageContainer}>
        <img
          src={lesson.imageUrl ? lesson.imageUrl : defaultImage}
          className={styles.imgElem}
        />
        <div className={styles.imageIcons}>
          <div className={styles.leftContainer}>
            {showLessonEditModal && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  showLessonEditModal(lesson.id);
                }}
                className={styles.editIcon}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            {canBeDeleted && (
              <button
                onClick={handleDeleteClick}
                className={styles.lessonDeleteIcon}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
          </div>
          <div className={styles.rightIconsContainer}>
            <div
              className={styles.bookmark}
              onClick={(e) => {
                e.preventDefault();
                void handleToggleBookmark();
              }}
            >
              <FontAwesomeIcon
                icon={bookmarkIcon}
                className={styles.bookmarkIcon}
              />
              {/** Todo get enrolled count */}
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                void handleShareLesson();
              }}
            >
              <FontAwesomeIcon
                icon={faShareNodes}
                style={{ color: "#29834D" }}
                className={styles.share}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.lessonDetails}>
        <div className={styles.title}>{lesson.title || "Untitled Lesson"}</div>
        <div className={styles.author}>
          Uploaded by
          {` ${author?.firstName ?? "Anonymous"} ${author?.lastName ?? "User"}`}
          {/* Todo get from backend */}
          <FontAwesomeIcon
            icon={faCircle}
            style={{ color: "#c1c3c2", fontSize: "6px" }}
          />
          {calculateTimeAgo(lesson.createdDate ?? "")}
        </div>
        <div
          className={styles.mobileShare}
          onClick={(e) => {
            e.preventDefault();
            void handleShareLesson();
          }}
        >
          <FontAwesomeIcon
            icon={faShareNodes}
            style={{ color: "#29834D" }}
            className={styles.share}
          />
        </div>
        {!canBeDeleted && lessonProgress > 0 && (
          <div className={styles.innerProgressContainer}>
            <CircularProgressBarComponent
              value={lessonProgress}
              innerCircleComponent={<div>{lessonProgress}%</div>}
            />
          </div>
        )}

        <span className={styles.lineDivider}></span>

        <div className={styles.infoContainer}>
          <div className={styles.learnersContainer}>
            <div className={styles.imageStack}>
              {enrolledUsers.slice(0, 3).map((user, index) =>
                user.profilePictureUrl ? (
                  <img
                    key={index}
                    src={user.profilePictureUrl}
                    alt="User"
                    className={styles.stackImage}
                    style={{ zIndex: index + 1 }}
                  />
                ) : (
                  <div
                    key={index}
                    className={styles.stackImage}
                    style={{
                      zIndex: index + 1,
                      backgroundColor: "#29834d",
                      color: "white",
                    }}
                  >
                    {user.firstName ? user.firstName[0] : "A"}.
                    {user.lastName ? user.lastName[0] : "U"}
                  </div>
                )
              )}
            </div>
            {enrolledUsers.length > 0 && (
              <div className={styles.learnerText}>
                +{enrolledUsers.length} Learner
                {enrolledUsers.length > 1 ? "s" : ""}
              </div>
            )}
          </div>
          <div className={styles.iconWithText}>
            <div
              className={styles.textBoxContainer}
              style={{
                background: `linear-gradient(180deg, ${organization.secondaryColor ?? "#66cc8d"} 0%, ${organization.primaryColor ?? "#55dddd"} 66.25%)`,
              }}
            >
              <div className={styles.textBox}>
                {lesson.duration
                  ? formatDuration(lesson.duration)
                  : "0 minutes"}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
