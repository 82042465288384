import React from "react";
import styles from "./FooterComponent.module.css";
import { useUserApiClient } from "../../../hooks/useUserApiClient";

export default function FooterComponent(): JSX.Element {
  const { organization } = useUserApiClient();
  return (
    <div className={styles.container}>
      <span className={styles.copyrightNotice}>
        &copy; {new Date().getFullYear()} {organization.name}.{" "}
        {organization.name !== "BuildQL" ? "Powered by BuildQL" : ""}
      </span>
      <div className={styles.links}>
        <a className={styles.link} href="#">
          Privacy
        </a>
        <a className={styles.link} href="#">
          Security
        </a>
        <a className={styles.link} href="#">
          Legal
        </a>
        <a className={styles.link} href="#">
          About Us
        </a>
      </div>
    </div>
  );
}
