import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import { useUserApiClient } from "./hooks/useUserApiClient";
import PasswordResetView from "./views/ForgotPassword/PasswordReset";
import PasswordResetRequestView from "./views/ForgotPassword/PasswordResetRequest";
import LessonView from "./views/Lesson";
import LessonCreationView from "./views/LessonCreation";
import LessonCreationDashboardView from "./views/LessonCreationDashboard";
import LoginView from "./views/Login";
import LogoutComponent from "./views/Logout";
import MenuView from "./views/Menu";
import OnboardingView from "./views/Onboarding";
import SavedLessonsView from "./views/SavedLessonsDashboard";
import SignupView from "./views/Signup";
import UserProfile from "./views/UserProfile";
import AnalyticsView from "./views/Analytics";
import { SavedLessonProvider } from "./views/common_components/SavedLessonContext/SavedLessonContext";
import LessonOutlineView from "./views/LessonOutline";
import { PromptOutlineProvider } from "./views/common_components/PromptOutlineContext/PromptOutlineContext";
import { SubscriptionCheckoutPage } from "./views/Subscription/Checkout/Checkout";
import { SubscriptionPage } from "./views/Subscription";
import CheckOrganizationComponent from "./views/common_components/CheckOrganizationComponent";

function App(): JSX.Element {
  const [lessonsSaved, setLessonsSaved] = React.useState<Set<string>>(
    new Set()
  );
  const { fetchUserBookmarkedLessons } = useUserApiClient();

  const [prompt, setPrompt] = React.useState("");
  const [outline, setOutline] = React.useState<string[]>([]);
  const { organization } = useUserApiClient();

  React.useEffect(() => {
    fetchUserBookmarkedLessons()
      .then((lessons) =>
        setLessonsSaved(new Set(lessons.bookmarkedLessons ?? []))
      )
      .catch(() => setLessonsSaved(new Set([])));
  }, []);

  React.useEffect(() => {
    document.title = organization.name;
    // change document favicon
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = organization.logoUrl ?? "";

    const appleFavicon = document.getElementById(
      "apple-favicon"
    ) as HTMLLinkElement;
    appleFavicon.href = organization.logoUrl ?? "";
  }, [organization]);

  return (
    <SavedLessonProvider
      savedLessons={new Set(lessonsSaved)}
      setSavedLessons={setLessonsSaved}
    >
      <PromptOutlineProvider
        outline={outline}
        setOutline={setOutline}
        prompt={prompt}
        setPrompt={setPrompt}
      >
        <CheckOrganizationComponent>
          <div className="App">
            <Router>
              <Switch>
                <Route path="/login">
                  <LoginView />
                </Route>
                <Route path="/signup">
                  <SignupView />
                </Route>
                <Route path="/analytics">
                  <AnalyticsView />
                </Route>
                <Route path="/finish-setup">
                  <OnboardingView />
                </Route>
                <Route path="/lesson/:lessonId">
                  <LessonView />
                </Route>
                <Route path="/lesson-creation/:lessonId">
                  <LessonCreationView />
                </Route>
                <Route path="/lesson-outline-generated/:lessonId">
                  <LessonOutlineView />
                </Route>
                <Route path="/lesson-creation">
                  <LessonCreationDashboardView />
                </Route>
                <Route path="/profile/:userId">
                  <UserProfile />
                </Route>
                <Route path="/logout">
                  <LogoutComponent />
                </Route>
                <Route path="/forgotpassword">
                  <PasswordResetRequestView />
                </Route>
                <Route path="/passwordReset">
                  <PasswordResetView />
                </Route>
                <Route path="/saved-lessons">
                  <SavedLessonsView />
                </Route>
                <Route path="/subscriptions">
                  <SubscriptionPage />
                </Route>
                <Route path="/subscription/checkout">
                  <SubscriptionCheckoutPage />
                </Route>
                <Route path="/">
                  <MenuView />
                </Route>
              </Switch>
            </Router>
          </div>
        </CheckOrganizationComponent>
      </PromptOutlineProvider>
    </SavedLessonProvider>
  );
}

export default App;
