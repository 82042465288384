import React from "react";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";

interface ProfilePictureUploadProps {
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}
export const ProfilePictureUploadButton = ({
  handleFileChange,
}: ProfilePictureUploadProps): JSX.Element => {
  const { organization } = useUserApiClient();
  return (
    <>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept="image/*"
        onChange={(event) => {
          handleFileChange(event).catch((error) => {
            console.error("Error handling file change:", error);
          });
        }}
      />
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => document.getElementById("fileInput")?.click()}
      >
        <g filter="url(#filter0_d_64_4578)">
          <circle
            cx="35"
            cy="31"
            r="15"
            fill={organization.primaryColor ?? "#29834D"}
          />
          <circle cx="35" cy="31" r="13.75" stroke="white" strokeWidth="2.5" />
        </g>
        <path
          d="M29.667 35.3334H40.3337M29.667 35.3334V32.6667L36.9127 25.421L36.9139 25.4198C37.1772 25.1566 37.309 25.0247 37.461 24.9753C37.5949 24.9318 37.7392 24.9318 37.873 24.9753C38.0249 25.0247 38.1567 25.1564 38.4195 25.4193L39.5794 26.5791C39.8434 26.8432 39.9755 26.9752 40.0249 27.1274C40.0684 27.2613 40.0684 27.4056 40.0249 27.5395C39.9755 27.6916 39.8436 27.8234 39.58 28.0871L39.5794 28.0876L32.3337 35.3334L29.667 35.3334Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_d_64_4578"
            x="0"
            y="0"
            width="70"
            height="70"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.054902 0 0 0 0 0.145098 0 0 0 0 0.0901961 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_64_4578"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_64_4578"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};
