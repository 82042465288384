import React from "react";
import styles from "./BuildExampleCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useUserApiClient } from "../../../../hooks/useUserApiClient";
import { Prompt } from "../../../../types/organization";
import { faBook } from "@fortawesome/free-solid-svg-icons";

interface BuildCardProps {
  prompt: Prompt;
  runFunction: (prompt: string) => Promise<void>;
  className?: string;
}

export const BuildExampleCard: React.FC<BuildCardProps> = ({
  prompt,
  runFunction,
  className = "",
}) => {
  const { organization } = useUserApiClient();

  return (
    <div
      className={`${styles.cardContainer} ${className}`}
      onClick={() => {
        void runFunction(prompt.title);
      }}
    >
      <div
        className={styles.cardIcon}
        style={{ color: organization.primaryColor }}
      >
        {prompt.icon?.type === "url" ? (
          <img src={prompt.icon.value} />
        ) : prompt.icon?.type === "fontawesome" ? (
          <FontAwesomeIcon icon={prompt.icon.value as IconProp} />
        ) : (
          <FontAwesomeIcon icon={faBook} />
        )}
      </div>
      <div className={styles.cardText}>
        <div className={styles.cardTitle}>{prompt.title}</div>
        <div className={styles.cardBody}>{prompt.description}</div>
      </div>
    </div>
  );
};
